<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters class="pt-3 justify-space-between">
                <div class="d-flex flex-row">
                    <v-select
                        :items="searchFields"
                        :placeholder="$t('message.searchColumn')"
                        class="filter-width-175 force-text-left"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="searchField"
                    >
                    </v-select>
                    <v-text-field
                        :placeholder="$t('message.searchTerm')"
                        append-icon="search"
                        autocomplete="off"
                        class="filter-width-175 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="searchTerm"
                        @keyup.enter="searchActiveSuspendedSuppliers()"
                    >
                    </v-text-field>
                    <v-btn
                        :loading="loading.search"
                        class="ml-2"
                        @click="searchActiveSuspendedSuppliers()"
                    >{{ $t('message.search') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.reset"
                        class="ml-2"
                        @click="resetSearch()"
                    >{{ $t('message.reset') }}
                    </v-btn>
                </div>
                <div class="d-flex flex-row">
                    <v-select
                        :items="supplierTypes"
                        :loading="loading.filter.types"
                        :placeholder="$t('message.type')"
                        autocomplete="off"
                        class="filter-width-150 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Suppliertype.id"
                        item-text="Suppliertype.title"
                        return-object
                        solo
                        v-model="filterOptions.type"
                    >
                        <template v-slot:item="{ item }">
                            <v-row class="justify-space-between">
                                <span class="font-sm">{{ item.Suppliertype.title }}</span>
                                <v-chip x-small color="orange">{{ item.Suppliertype.suppliers }}</v-chip>
                            </v-row>
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="supplierAgents"
                        :loading="loading.filter.agents"
                        :placeholder="$t('message.mainSupplier')"
                        autocomplete="off"
                        class="filter-width-175 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Agent.id"
                        item-text="Agent.code"
                        return-object
                        solo
                        v-model="filterOptions.agent"
                    >
                        <template v-slot:item="{ item }">
                            <v-row class="justify-space-between">
                                <span class="font-sm">{{ item.Agent.code }}</span>
                                <v-chip x-small color="orange">{{ item.Agent.suppliers }}</v-chip>
                            </v-row>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        :items="supplierSpecies"
                        :loading="loading.filter.species"
                        :placeholder="$t('message.species')"
                        autocomplete="off"
                        class="filter-width-150 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Species.id"
                        item-text="Species.title"
                        return-object
                        solo
                        v-model="filterOptions.species"
                    >
                        <template v-slot:item="{ item }">
                            <v-row class="justify-space-between">
                                <span class="font-sm">{{ item.Species.title }}</span>
                                <v-chip x-small color="orange">{{ item.Species.suppliers }}</v-chip>
                            </v-row>
                        </template>
                    </v-autocomplete>
                    <ExportTableJson
                        :export-conditions="exportConditions"
                        :export-data="filteredActiveSuspendedSuppliers"
                        :export-fields="headers"
                        :export-source="'suppliers'"
                        class="ml-3"
                        style="padding-top: 0px !important;"
                    ></ExportTableJson>
                </div>
            </v-row>
		</v-sheet>
		<v-overlay
			:value="loading.suppliers"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
        <v-overlay
            :value="loading.viewProfile"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
		<v-data-table
			:footer-props="{
                itemsPerPageOptions: [10,20,50,-1],
                showFirstLastPage: true
            }"
			:headers="headers"
			:height="filteredActiveSuspendedSuppliers.length < 2 ? '150px' : null"
			:hide-default-header="hideDefaultheader"
            :items="filteredActiveSuspendedSuppliers"
			:loading="loading.suppliers"
			:options.sync="tableOptions"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="supplierTable"
			item-key="Supplier.id"
		>
			<template v-slot:item.Supplier.otsname="{ item }">
				<span :class="'font-weight-bold' + (item.Supplier.title != null && item.Supplier.title.length < 30 ? ' text-no-wrap' : '')">{{ item.Supplier.otsname ? item.Supplier.otsname : item.Supplier.title }}</span>
			</template>
            <template v-slot:item.Supplier.type="{ item }">
                <span class="text-no-wrap">{{ item.Supplier.type }}</span>
            </template>
            <template v-slot:item.Supplier.supplierstatus_id="{ item }">
                <span class="green--text darken-1" v-if="item.Supplier.supplierstatus_id == 1">{{ $t('message.activated') }}</span>
                <span class="red--text darken-1" v-else>{{ $t('message.suspended') }}</span>
            </template>
			<template v-slot:item.Product1.title="{ item }">
				<div class="d-flex flex-column">
					<span class="text-no-wrap">{{ item.Product1.title }}</span>
					<span class="text-no-wrap">{{ item.Product2.title }}</span>
					<span class="text-no-wrap">{{ item.Product3.title }}</span>
				</div>
			</template>
			<template v-slot:item.Species.title="{ item }">
				<span :class="'text-no-wrap' + (item.Species.title == '' || item.Species.title == null ? ' red--text' : '')">{{ item.Species.title ? item.Species.title : $t('message.valueMissing') }}</span>
			</template>
			<template v-slot:item.Company.address1="{ item }">
				<span>{{ [item.Company.address1,item.Company.address2,item.Company.address3].join(' ') }}</span>
			</template>
			<template v-slot:item.Company.city="{ item }">
				<span :class="'text-no-wrap' + (item.Company.city == '' || item.Company.city == null ? ' red--text' : '')">{{ item.Company.city ? item.Company.city : $t('message.valueMissing') }}</span>
			</template>
			<template v-slot:item.Company.state="{ item }">
				<span :class="'text-no-wrap' + (item.Company.state == '' || item.Company.state == null ? ' red--text' : '')">{{ item.Company.state ? item.Company.state : $t('message.valueMissing') }}</span>
			</template>
			<template v-slot:item.Country.name="{ item }">
				<span :class="'text-no-wrap' + (item.Country.name == '' || item.Country.name == null ? ' red--text' : '')">{{ item.Country.name ? item.Country.name : $t('message.valueMissing') }}</span>
			</template>
			<template v-slot:item.Supplier.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
                        <v-list-item class="font-sm" @click="viewSupplierProfile(item.Supplier.id)" v-if="$can('read','Supplier')">
                            <v-icon color="green darken-1" small class="mr-1 mdi mdi-eye"></v-icon>
                            {{ $t('message.viewSupplier') }}
                        </v-list-item>
						<v-list-item class="font-sm" @click="updateSupplier(item.Supplier.id)" v-if="$can('update','Supplier')">
							<v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
							{{ $t('message.updateSupplier') }}
						</v-list-item>
						<v-list-item class="font-sm" @click="suspendSupplier(item.Supplier.id)" v-if="item.Supplier.supplierstatus_id == 1 && $can('update','Supplier')">
							<v-icon small class="mr-1" color="red">pause</v-icon>
							{{ $t('message.suspendSupplier') }}
						</v-list-item>
                        <v-list-item class="font-sm" @click="activateSupplier(item.Supplier.id)" v-if="item.Supplier.supplierstatus_id != 1 && $can('update','Supplier')">
                            <v-icon small class="mr-1" color="green darken-1">mdi-play</v-icon>
                            {{ $t('message.activateSupplier') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="openDeleteSupplierDialog(item.Supplier.id, item.Supplier.otsname)" v-if="$can('destroy','Supplier')">
                            <v-icon color="red lighten-1" small class="mr-1">delete</v-icon>
                            {{ $t('message.deleteSupplier') }}
                        </v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
        <DeleteSupplier
            :dialog.sync="deleteSupplierDialog"
            :supplier-id="selectedSupplierId"
            :supplier-name="selectedSupplierName"
            :page-key="pageKey"
            @dialog-closed="deleteSupplierDialogClosed"
            @delete-done="supplierDeleted"
        ></DeleteSupplier>
        <SupplierProfileView
            :supplier-id="selectedSupplierIdToView"
            @pdf-printed="profilePrinted()"
            @pdf-not-printed="profileNotPrinted()"
            v-if="viewProfile"
        >
        </SupplierProfileView>
	</div>
</template>

<script>
	// import SimpleAlert from "./SimpleAlert";
	import {mapActions, mapGetters} from 'vuex'
	import {formatDate} from "../../helpers/helpers";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields"

    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const SimpleAlert = () => import("./SimpleAlert");
    const DeleteSupplier = () => import("Components/Appic/DeleteSupplier");
    const SupplierProfileView = () => import("Components/Appic/SupplierProfileView");

	export default {
		name: "SuppliersListing",
		components: {SupplierProfileView, DeleteSupplier, ExportTableJson, SimpleAlert },
		data() {
			return {
                deleteSupplierDialog: false,
				dialogs: {
					error: false,
					error_message: ""
				},
                exportConditions: {},
				filterOptions: {},
                hideDefaultheader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						agents: false,
						countries: false,
						species: false,
						types: false
					},
					search: false,
					suppliers: false,
                    viewProfile: false
				},
				searchField: 'Supplier.otsname', //default search field
				searchTerm: null,
                selectedSupplierId: null,
                selectedSupplierName: null,
                selectedSupplierIdToView: null,
				tableOptions: {},
                viewProfile: false
			}
		},
		computed: {
			...mapMultiRowFields('supplier', {
				allSuppliers: 'allSuppliers',
                allActiveSuspendedSuppliers: 'allActiveSuspendedSuppliers',
                filteredActiveSuspendedSuppliers: 'filteredActiveSuspendedSuppliers',
				filteredSuppliers: 'filteredSuppliers'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters('supplier', {
				supplierAgents: 'supplierAgents',
				supplierCountries: 'supplierCountries',
				supplierSpecies: 'supplierSpecies',
				supplierTypes: 'supplierTypes'
			}),
            ...mapGetters('user',{
                User: 'User'
            }),
			console: () => console,
			formatDate: () => formatDate,
			headers() {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Supplier.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.type'),
                        value: 'Supplier.type',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.status'),
                        value: 'Supplier.supplierstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.otsName'),
                        value: 'Supplier.otsname',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: true
                    },
					{
						id: 4,
						text: this.$t('message.code'),
						value: 'Supplier.code',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: true
					},
					{
						id: 5,
						text: this.$t('message.mainSupplier'),
						value: 'Agent.code',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left text-no-wrap',
						searchable: true,
                        sortable: true
					},
					{
						id: 6,
						text: this.$t('message.products'),
						value: 'Product1.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 7,
						text: this.$t('message.species'),
						value: 'Species.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 8,
						text: this.$t('message.address'),
						value: 'Company.address1',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 9,
						text: this.$t('message.city'),
						value: "Company.city",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
						id: 10,
						text: this.$t('message.state'),
						value: "Company.state",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 11,
						text: this.$t('message.country'),
						value: 'Country.name',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: true
					}
				]
				return headers
			},
            pageKey() {
                return Math.floor(Math.random() * 100)
            },
			searchFields() {
				return this.headers.filter((header) => header.searchable === true)
			}
		},
		methods: {
			...mapActions('supplier', {
                activateSupplierById: 'activateSupplierById',
			    filterAllActiveSuspendedSuppliers: 'filterAllActiveSuspendedSuppliers',
			    filterAllSuppliers: 'filterAllSuppliers',
                getAllActiveSuspendedSuppliers: 'getAllActiveSuspendedSuppliers',
                getAllSuppliers: 'getAllSuppliers',
				getAllSupplierAgents: 'getAllSupplierAgents',
				getAllSupplierCountries: 'getAllSupplierCountries',
				getAllSupplierSpecies: 'getAllSupplierSpecies',
				getAllSupplierTypes: 'getAllSupplierTypes',
				resetAllSuppliers: 'resetAllSuppliers',
                searchAllActiveSuspendedSuppliers: 'searchAllActiveSuspendedSuppliers',
				searchAllSuppliers: 'searchAllSuppliers',
				suspendSupplierById: 'suspendSupplierById'
			}),
            activateSupplier(val) {
                this.activateSupplierById(val).then((status) => {
                    if (status == 'done') {
                        this.$toast.success(this.$t('message.successes.supplierActivated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetAllSuppliers()
                            .then(() => {
                                this.getAllActiveSuspendedSuppliers()
                                    .then((response) => {
                                        if(response == 'done'){
                                            this.searchActiveSuspendedSuppliers()
                                        }
                                    })
                            })
                            .catch(() => {

                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.supplierNotActivated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            },
            supplierDeleted() {
                this.resetAllSuppliers()
                this.getAllActiveSuspendedSuppliers()
            },
            deleteSupplierDialogClosed() {
                this.deleteSupplierDialog = false;
            },
			dialogClosed() {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
            filterActiveSuspendedSuppliers() {
                this.loading.suppliers = true;
                let payload = {
                    filterOptions: this.filterOptions
                }
                this.filterAllActiveSuspendedSuppliers(payload)
                    .then(() => {
                        this.loading.suppliers = false;
                    })
                    .catch(() => {
                        this.loading.suppliers = false;
                    })
                    .finally(() => {
                        this.loading.suppliers = false;
                    })
            },
			filterSuppliers() {
				this.loading.suppliers = true;
				let payload = {
					filterOptions: this.filterOptions
				}
				this.filterAllSuppliers(payload)
					.then(() => {
						this.loading.suppliers = false;
					})
					.catch(() => {
						this.loading.suppliers = false;
					})
					.finally(() => {
						this.loading.suppliers = false;
					})
			},
            openDeleteSupplierDialog( supplierId, supplierName ) {
                this.selectedSupplierId = supplierId
                this.selectedSupplierName = supplierName
                this.deleteSupplierDialog = true;
            },
            profileNotPrinted() {
                this.selectedSupplierIdToView = null
                this.viewProfile = false
                this.loading.viewProfile = false
            },
            profilePrinted() {
                this.selectedSupplierIdToView = null
                this.viewProfile = false
                this.loading.viewProfile = false
            },
			resetSearch() {
				this.searchField = 'Supplier.otsname'; //default search field
				this.searchTerm = null;
				this.filterOptions = {};
			},
            searchActiveSuspendedSuppliers() {
                if (this.searchField == null || this.searchTerm == null) {
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
                } else {
                    this.loading.search = true;
                    this.tableOptions.page = 1
                    let payload = {
                        search: {
                            field: this.searchField,
                            value: this.searchTerm
                        }
                    }
                    this.searchAllActiveSuspendedSuppliers(payload)
                        .then(() => {
                            this.loading.search = false
                        })
                }
            },
			searchSuppliers() {
				if (this.searchField == null || this.searchTerm == null) {
					this.dialogs.error = true
					this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
				} else {
					this.loading.search = true;
                    this.tableOptions.page = 1
					let payload = {
						search: {
							field: this.searchField,
							value: this.searchTerm
						}
					}
					this.searchAllSuppliers(payload)
						.then(() => {
							this.loading.search = false;
						})
				}
			},
			async suspendSupplier(val) {
				if (await this.$root.$confirm(this.$t('message.suspendSupplier'), this.$t('message.confirmations.continueSupplierSuspendAction'), {color: 'orange'})) {
					this.suspendSupplierById(val).then((status) => {
						if (status == 'done') {
							this.$toast.success(this.$t('message.successes.supplierSuspended'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
                            this.resetAllSuppliers()
                                .then(() => {
                                    this.getAllActiveSuspendedSuppliers()
                                        .then((response) => {
                                            if(response == 'done'){
                                                this.searchActiveSuspendedSuppliers()
                                            }
                                        })
                                })
                                .catch(() => {

                                })
						} else {
							this.$toast.error(this.$t('message.errors.supplierNotSuspended'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
				}
			},
			updateSupplier(val) {
                let tab = window.open('/v1/suppliers/update/' + val, '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
            viewSupplierProfile(val) {
                this.selectedSupplierIdToView = val
                this.loading.viewProfile = true
                this.viewProfile = true
            }
		},
		watch: {
			filterOptions: {
				handler() {
					if (!this.loading.search) {
						// this.filterSuppliers()
                        this.filterActiveSuspendedSuppliers()
					}
				},
				deep: true
			}
		},
		created() {
			this.resetAllSuppliers()
		    // if (this.allSuppliers.length == 0) this.getAllSuppliers()
            if (this.allActiveSuspendedSuppliers.length == 0) this.getAllActiveSuspendedSuppliers()

			//get all filter options
			this.loading.filter.agents = true;
			this.loading.filter.countries = true;
			this.loading.filter.species = true;
			this.loading.filter.types = true;
			this.getAllSupplierTypes().then(() => {
				this.loading.filter.types = false
				return 'ok'
			})
				.then(this.getAllSupplierAgents())
				.then(() => {
					this.loading.filter.agents = false
					return 'ok'
				})
				.then(this.getAllSupplierCountries())
				.then(() => {
					this.loading.filter.countries = false
					return 'ok'
				})
				.then(this.getAllSupplierSpecies())
				.then(() => {
					this.loading.filter.species = false
					return 'ok'
				})
				.then(()=>{
					this.resetSearch()
				})
		}
	}
</script>

<style>
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
</style>